<template>  
	<div class="tw-w-full tw-h-full tw-relative tw-overflow-hidden">
		<WaitingSlot
		v-if="isLoading"
		theme="white"
		size="200px"
		class="tw-z-[5] tw-absolute tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.12)]"
		/>

		<AuscultationDiaporamaMap
		class="tw-absolute tw-transition-[all]"
		:class="{
			'tw-translate-x-full': isDiaporamaOpen
		}"
		:map="map"
		:photosGeoJson="photosData"
		:backPhotosGeoJson="backPhotosData"
		:floatDocGeoJson="floatDocData"
		:docSettings="docSettings"
		:isOpen="isDiaporamaOpen"
		:indexPopup="index"
		:isGroundSurvey="campaign.isGroundSurvey"
		@updateModal="updateModalInfos($event)"
		@closeModal="isDiaporamaOpen = false"
		@reopenModal="isDiaporamaOpen = true"
		/>

		<AuscultationFormMap
		class="tw-absolute"
		:class="{
			'tw-translate-x-full': isEditOpen,
			'tw-left-full' : !isEditOpen
		}"
		:loadedData="loadedData"
		:mapLayerId="lastClickedLayer ? lastClickedLayer.id : null"
		@layerContentChanged="onLayerContentChanged"
		/>

		<div
		id="mapContainer"
		class="tw-w-full tw-h-full tw-overflow-hidden tw-relative"
		>
			<slot/>

			<div
			id="geocoder"
			class="tw-absolute tw-z-[1] tw-top-[10px] tw-right-[15px]"
			ref="geocoder"
			/>

			<!--<map-nav
			:map="map"
			:draw="draw"
			:tableHeight="tableHeight"
			@beforeStyleChange="beforeStyleChange"
			@styleChanged="styleChanged"
			:measuringTapeContainerId="'distance'"
			:isMapInReloading="isMapInReloading"
			:currentZoom="lastZoom"
			/>-->
			
			<map-prs-nav
			:map="map"
			:draw="draw"
			@displayAUPrs="displayAUPrs"
			@displayRNPrs="displayRNPrs"
			@displayRDPrs="displayRDPrs"
			:AUPrsLayerInfo="AUPrsLayerInfo"
			:RNPrsLayerInfo="RNPrsLayerInfo"
			:RDPrsLayerInfo="RDPrsLayerInfo"
			:measuringTapeContainerId="'distance'"
			:currentZoom="lastZoom"
			:campaign="campaign"
			ref="prsNav"
			/>

			<MapNavButton
			class="navButton"
			:map="map"
			:draw="draw"
			style="z-index: 2"
			/>

			<v-btn
			depressed
			color="#f1f1f1"
			v-if="isDiaporamaButtonDisplayed"
			@click="isDiaporamaOpen = !isDiaporamaOpen"
			class="tw-absolute tw-transition-[all] diaporama_btn"
			:class="{
				'tw-left-[50%]': isDiaporamaOpen
			}"
			>
				{{ isDiaporamaOpen ? "Fermer diaporama" : "Afficher diaporama" }}
				<v-icon color="black">
					{{
						isDiaporamaOpen ? "mdi-chevron-up" : "mdi-chevron-down"
					}}
				</v-icon>
			</v-btn>

			<v-btn
			depressed
			color="#f1f1f1"
			v-if="loadedData !== null && $hasRight('campaigns.openAuscultationForm')"
			@click="isEditOpen = !isEditOpen"
			class="tw-absolute tw-transition-[all] edit_btn"
			:class="{
				'tw-left-[80%]': isEditOpen
			}"
			>
				{{ isEditOpen ? "Fermer le formulaire" : "Afficher le formulaire" }}
				<v-icon color="black">
					{{
						isEditOpen ? "mdi-chevron-up" : "mdi-chevron-down"
					}}
				</v-icon>
			</v-btn>

			<div class="bottom-left-gizmo">
				<div
				id="distance"
				class="distance-container"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import auth from "../../../service/auth";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import configUtils from "../../../utils/config.utils";
import popupUtils from "../../../utils/map/popup.utils";
import MapPopup from "../../popup/Auscultation.map.popup.vue";
import store from "@/store";
import * as turf from "@turf/turf";
import mapNav from "../../map/Map.nav.vue";
import mapPrsNav from "../../map/Map.prs.nav.vue";
import AuscultationDiaporamaMap from "../map/AuscultationDiaporama.map.vue";
import AuscultationFormMap from "./AuscultationForm.map.vue";
import MAPLAYER_STATUS from "../../../utils/map/maplayerstatus.const";
import MAPLAYER_TYPE from "../../../utils/map/maplayertype.const";
import SYMBOL_TYPE from "../../../utils/map/symboltype.const";
import MAPLAYER_ZOOM from "../../../utils/map/maplayerzoom.const";
import {LayerUtils} from "../../../utils/map/map.utils";
import ImageUtils from "../../../utils/image";
import MapNavButton from "../../map/Map.Nav.button.vue";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
export default {
	name: "AuscultationMap",
	components: {
		mapNav, mapPrsNav, AuscultationDiaporamaMap, AuscultationFormMap, MapNavButton
	},
	props: [
	  "selectedLayers",
	  "filters",
	  "layersList",
	  "AUPrsLayerInfo",
	  "RNPrsLayerInfo",
	  "RDPrsLayerInfo",
	  "filterValues",
	  "campaign"
	],
	data(){
	  return {
			accessToken:
		  "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
			location: [],
			index: null,
			map: {},
			lastZoom: 12,
			mapLoaded: false,
			defaultCenter: [2.213749, 46.227638],
			photosData: {},
			backPhotosData: null,
			tableHeight: 250,
			isMapInReloading: false,
			isDiaporamaOpen: false,
			isDiaporamaButtonDisplayed: false,
			popup: null,
			AUPrsLayerUtils: null,
			RNPrsLayerUtils: null,
			RDPrsLayerUtils: null,
			selectedRoad: null,
			isLoading: false,
			lastClickedLayer: null,
			currentGid: null,
			oldPopup: null,
			clickedFeatures: null,
			pointCollection: [],
			isEditButtonDisplayed: true,
			isEditOpen: false,
			loadedData: null,
			floatDocData: [],
			docSettings: [],
			draw: null
	  };
	},
	beforeMounted(){},
	computed: {
		updatedLayersEvent(){
			return this.mapLoaded && this.selectedLayers;
		},
		filterEvent(){
			return this.mapLoaded && this.filters;
		}
	},
	watch: {
		filterEvent: function(){
			if(this.mapLoaded){
				this.filters.forEach(el => {
					let filter = ["any", ...el.filters];
					this.map.setFilter(el.id, filter);
					let layerId = el.id;
					layerId = layerId.substr(0, layerId.length - 3);
					let layerLabelId = layerId + "_Label";
					if(this.map.getLayer(layerLabelId)){
						this.map.setFilter(layerLabelId, filter);
					}
				});
			}
		},
		updatedLayersEvent: function(){
			if(this.mapLoaded){
				this.reload3DLayers(this.layersList);
				this.updateLayers(this.selectedLayers, this.layersList);
				/*let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
				if(visibleLayers.length == 0){
					visibleLayers = this.getDataLayersIdList(this.layersList);
				}*/

				let visibleLayers = this.selectedLayers.map(layer => parseInt(layer.id.split("_")[0]));
				this.updateLayerBounds(visibleLayers, false);
			}
		},
		filterValues: {
			handler(){
				this.reloadLayers();
				let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
				if(visibleLayers.length > 0){
					this.updateLayerBounds(visibleLayers, false);
				}
			},
			deep: true
	  	}
	},
	methods: {
		async updateModalInfos(diapoIndex){
			let nearestPoint = turf.nearest(
				turf.point(diapoIndex.geometry.coordinates),
				turf.featureCollection(this.pointCollection)
			);

			let index = nearestPoint.properties.index;
			let newGid = this.clickedFeatures[index].properties.gid;

			let itemData;

			if(newGid !== this.currentGid){
				itemData = await this.$axios.get(
					"map-layers/" + this.lastClickedLayer.id + "/data/" + newGid,
					{showLoader: false}
				);
				this.loadedData = itemData.data;
				this.oldPopup = itemData;
				this.currentGid = newGid;
			}
			else {
				itemData = this.oldPopup;
			}
			this.popup = popupUtils.generatePopup(
				diapoIndex.geometry.coordinates[0],
				diapoIndex.geometry.coordinates[1],
				itemData.data,
				this.map,
				MapPopup,
				() => {
					this.popup = null;
					this.loadedData = null;
					this.isEditOpen = false;
				},
				null,
				this.lastClickedLayer
			);

			let padding = this.map.getCanvas().width / 2;
			this.map.flyTo({
				center: diapoIndex.geometry.coordinates,
				padding: {left: padding}
			});
		},
		indexOfArray(val, array){
			var hash = {};
			for(var i = 0; i < array.length; i++){
				hash[array[i]] = i;
			}
			return (hash.hasOwnProperty(val)) ? hash[val] : -1;
		},
		reloadLayers(){
			this.layersList.forEach(() => {
				Object.keys(this.map.style._sourceCaches).forEach(key => {
					if(key.startsWith("other:") || key.startsWith("symbol:")){
						let source = this.map.style._sourceCaches[key];
						source.clearTiles();
						source.update(this.map.transform);
					}
				});
			});
			this.map.triggerRepaint();
		},
		flyTo(coordinates, zoom = 15){
			this.map.jumpTo({
				center: coordinates,
				essential: true,
				zoom: zoom
			});
		},
		getLayerId(layer){
			return layer.id + "_Id";
		},
		getSourceId(layer){
			return layer.id + "_Source";
		},
		getLabelLayerId(layer){
			return layer.id + "_Label";
		},
		getLabelSourceId(layer){
			return layer.id + "_LabelSource";
		},
		removeLayer(layer){
			let layerId = this.getLayerId(layer);
			this.map.removeLayer(layerId);
			if(layer.displayColumn){
				let labelLayerId = this.getLabelLayerId(layer);
				this.map.removeLayer(labelLayerId);
			}
		},
		async mapInit(){
			let mapStyle = "mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj";
			if(store.state.campaign.current.mapStyle === "satellite") mapStyle = "mapbox://styles/mapbox/satellite-v9";
			mapboxgl.accessToken = this.accessToken;
			let center = this.defaultCenter;
			if("geolocation" in navigator){
				navigator.geolocation.getCurrentPosition(pos => {
					center = [pos.coords.longitude, pos.coords.latitude];
				});
			}
			else if(store.state.campaign.current.center) center = store.state.campaign.current.center;
			const isGuest = auth.isLoggedGuest();
			const isLogged = auth.isLogged();
			const authorization =
			!isLogged && isGuest ? auth.getGuestToken() : "Bearer " + auth.getJwt();
			this.map = new mapboxgl.Map({
				container: "mapContainer",
				style: mapStyle,
				center,
				zoom: 5,
				transformRequest: (url, resourceType) => {
					if(resourceType === "Tile" && 
						url.startsWith(process.env.VUE_APP_BASE_URL)){
						return {
							url: url,
							headers: {Authorization: authorization}
						};
					} 
				}
			});
			let geocoder = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
				placeholder: this.$t("search"),
				flyTo: {duration: 0}
			});
			this.$refs["geocoder"].appendChild(geocoder.onAdd(this.map));
			this.map.on("load", () => {
				this.map.resize();
				let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
				if(visibleLayers.length > 0){
					this.updateLayerBounds(visibleLayers, true);
				}
			});
			this.map.on("style.load", () => {
				this.resetLayers();
				this.$refs.prsNav.updateMap();
			});
			this.map.on("styleimagemissing", (e) => {
				if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.DIRECTION + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let color = e.id.substring(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.DIRECTION.length + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR.length);
					let img = require("@/assets/images/symbols/direction/64x64.png");
					ImageUtils.replaceColor(img, color, "#000000", e.id, this.addSymbolIcon);
				}
				else if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.FONTAWESOME + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let symbolInfos = e.id.split(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR);
					let color = symbolInfos[3];
					let imageName = symbolInfos[2];
					let className = symbolInfos[1];
					let img = require("@/assets/images/symbols/fontawesome/64x64/" + className + "/" + imageName + ".png");
					ImageUtils.replaceColor(img, color, "#000000", e.id, this.addSymbolIcon);
				}
				else if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.VERTICAL_SIGNAL + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let symbolInfos = e.id.split(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR);
					let imageName = symbolInfos[1];
					let img = require("@/assets/images/symbols/verticalsignal/64x64/" + imageName + ".png");
					this.addSymbolIcon(img, e.id);
				}
				else if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.HORIZONTAL_SIGNAL + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let symbolInfos = e.id.split(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR);
					let imageName = symbolInfos[1];
					let img = require("@/assets/images/symbols/horizontalsignal/64x64/" + imageName + ".png");
					this.addSymbolIcon(img, e.id);
				}
				else if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.PERSONAL_ICON + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let symbolInfos = e.id.split(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR);
					let imageName = symbolInfos[1];
					let img = require("@/assets/images/symbols/personal/64x64/" + imageName + ".png");
					this.addSymbolIcon(img, e.id);
				}
				else if(e.id.startsWith(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.PR + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR)){
					let color = e.id.substring(SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.PR.length + SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR.length);
					let img = require("@/assets/images/symbols/pr/64x64.png");
					ImageUtils.replaceColor(img, color, "#000000", e.id, this.addSymbolIcon);
				}
	  		});
			this.map.on("zoom", () => {
				let currentZoom = this.map.getZoom();
				this.reload3DLayers(this.layersList);
				this.lastZoom = currentZoom;
			});
			this.lastZoom = this.map.getZoom();

			this.draw = new MapboxDraw({
				displayControlsDefault: false,
			});
			this.map.addControl(this.draw);

			// Écouter l'événement de création de polygone
			this.map.on("draw.create", this.handleDrawCreate);

			this.map.on("draw.delete", this.handleDrawDelete);

			this.map.on("draw.update", this.handleDrawCreate);
		},
		handleDrawDelete(e){
			console.log("Polygons deleted:", e.features);
		},
		handleDrawCreate(e){
			const polygon = e.features[0];
			const polygonCoordinates = polygon.geometry.coordinates[0];
			// Récupérer les features dans le polygone
			const features = this.map.queryRenderedFeatures({
				layers: [this.selectedLayers[0].id], // Remplacer par l'ID de votre couche
				filter: [
					"within", {
						type: "Polygon",
						coordinates: [polygonCoordinates]
					}
				]
			});

			console.log(features);

			// Extraire les ID des features
			const featureIds = features.map(feature => feature.properties.gid);

			console.log("Selected feature IDs:", featureIds);
		},
		async updateLayerBounds(visibleLayers, centerlized = true){
			let bounds = null;
			if(visibleLayers && visibleLayers.length > 0){
				let boundingBox = await this.$axios.get(
					"map-layers/bounding-box?map-list=" + visibleLayers.join(",")
				);
				if(
					null !== boundingBox &&
				null !== boundingBox.data &&
				Object.keys(boundingBox.data).length !== 0
				){
					bounds = turf.bbox(boundingBox.data);
					if(centerlized){
						this.map.fitBounds(bounds, {
							duration: 0, padding: {
								top: 100, bottom: 50, left: 50, right: 50
							},
						});
					}
				}
			}
			if(null !== bounds){
				this.$store.commit("campaign/set_current", {
					bounds: bounds
				});
			}
		},
		addCommonLayer(layer){
			if(layer){
				let commonLayerUtils = new LayerUtils(
					store.state.campaign.current,
					this.map,
					layer,
					`${process.env.VUE_APP_BASE_URL}map-layers/{map-layer-id}/tiles/{z}_{x}_{y}.pbf`
				);
				this.handleLabelPointerClick(this.map, layer);
				commonLayerUtils.moveToDeepest();
				return commonLayerUtils;
			}
			return null;
		},
		displayAUPrs(showed){
			if(this.AUPrsLayerInfo){
				if(!this.AUPrsLayerUtils){
					this.AUPrsLayerUtils = this.addCommonLayer(this.AUPrsLayerInfo);
				}
				if(showed){
					this.AUPrsLayerUtils.showLayer();
				}
				else {
					this.AUPrsLayerUtils.hideLayer();
				}
			}
		},
		displayRNPrs(showed){
			if(this.RNPrsLayerInfo){
				if(!this.RNPrsLayerUtils){
					this.RNPrsLayerUtils = this.addCommonLayer(this.RNPrsLayerInfo);
				}
				if(showed){
					this.RNPrsLayerUtils.showLayer();
				}
				else {
					this.RNPrsLayerUtils.hideLayer();
				}
			}
		},
		displayRDPrs(showed){
			if(this.RDPrsLayerInfo){
				if(!this.RDPrsLayerUtils){
					this.RDPrsLayerUtils = this.addCommonLayer(this.RDPrsLayerInfo);
				}
				if(showed){
					this.RDPrsLayerUtils.showLayer();
				}
				else {
					this.RDPrsLayerUtils.hideLayer();
				}
			}
		},
		addSymbolIcon(imageUrl, imageName){
			this.map.loadImage(imageUrl, 
				(error, image) => {
					if(error) throw error;
					if(!this.map.hasImage(imageName)){
						this.map.addImage(imageName, image);
					}
				}
			);
		},
		addMapLayer(layer){
			let layerId = this.getLayerId(layer);
			let currentZoom = this.map.getZoom();
			let tileSource = "tile";
			let geomType = layer.geomType;
			let orgGeomType = layer.orgGeomType;
			let symbolType = layer.symbolType;
			let symbolInfo = layer.symbolInfo;
			if(geomType == MAPLAYER_TYPE.POLYGON3D){
				if(currentZoom < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL){
					if(MAPLAYER_TYPE.LINESTRING == orgGeomType || MAPLAYER_TYPE.MULTI_LINESTRING == orgGeomType){
						tileSource = "linestring";
						geomType = MAPLAYER_TYPE.LINESTRING;
					}
					else {
						tileSource = "polygon";
						geomType = MAPLAYER_TYPE.POLYGON;
					}					
				}
				else {
					tileSource = "3D";
					geomType = MAPLAYER_TYPE.POLYGON3D;
				}
			}
			let config = configUtils.getfilterConfig(
				geomType,
				symbolType,
				symbolInfo,
				layer.displayColumn,
				geomType,
				0
			);
			if(this.selectedLayers.find(e => e.id === layerId) != undefined){
				config.layout["visibility"] = "visible";
			}
			else {
				config.layout["visibility"] = "none";
			}

			this.map.addLayer({
				id: layerId,
				source: this.getSourceId(layer),
				"source-layer": tileSource,
               	minzoom: layer.minZoom ? layer.minZoom : 0,
	            maxzoom: layer.maxZoom ? layer.maxZoom : 24,
				...config
			});
			if(layer.displayColumn){
				config = configUtils.getfilterConfig(
					MAPLAYER_TYPE.LABEL,
					SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DEFAULT,
					null,
					layer.displayColumn,
					geomType,
					0
				);
				if(this.selectedLayers.find(e => e.id === layerId) != undefined){
					config.layout["visibility"] = "visible";
				}
				else {
					config.layout["visibility"] = "none";
				}
				this.map.addLayer({
					id: this.getLabelLayerId(layer),
					source: this.getLabelSourceId(layer),
					"source-layer": tileSource,
					minzoom: layer.labelMinZoom ? parseInt(layer.labelMinZoom) : 0,
					maxzoom: layer.labelMaxZoom ? parseInt(layer.labelMaxZoom) : 24,
					...config
				});
			}
			this.handlePointerClick(this.map, layer);
		},
		async initLayers(list){
			let initLayer = layer => {
				if(layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED){
					return;
				}

				let geomType = layer.geomType;
				let tileType = "vector";
				let tileSize = 512;
				let tileTpl = `${process.env.VUE_APP_BASE_URL}map-layers/${layer.id}/tiles/{z}_{x}_{y}.pbf`;
				if(geomType === MAPLAYER_TYPE.TILE){
					tileType = "raster";
					tileSize = 256;
					tileTpl = `${process.env.VUE_APP_BASE_URL}map-layers/${layer.id}/raster-tiles/{z}_{x}_{y}.png`;
				}

				let sourceId = this.getSourceId(layer);
				let existedSource = this.map.getSource(sourceId);
				if(!existedSource){
					this.map.addSource(sourceId, {
						type: tileType,
						tiles: [tileTpl],
						tileSize: tileSize
					});
					if(layer.displayColumn){
						let sourceLabelId = this.getLabelSourceId(layer);
						this.map.addSource(sourceLabelId, {
							type: "vector",
							tiles: [tileTpl]
						});
					}
				}
				let layerId = this.getLayerId(layer);
				let existedLayer = this.map.getLayer(layerId);
				if(existedLayer){
					this.removeLayer(layer);
				}
				this.addMapLayer(layer);
			};
			for(const layer of list){
				if(layer.isContainer){
					await this.initLayers(layer.children);
				}
				else {
					initLayer(layer);
				}
			}
			if(this.mapLoaded){
				this.filters.forEach(el => {
					this.map.setFilter(el.id, ["any", ...el.filters]);
				});
			}
			if(this.AUPrsLayerInfo){
				if(!this.AUPrsLayerUtils){
					this.AUPrsLayerUtils = this.addCommonLayer(this.AUPrsLayerInfo);
				}
			}
			if(this.RNPrsLayerInfo){
				if(!this.RNPrsLayerUtils){
					this.RNPrsLayerUtils = this.addCommonLayer(this.RNPrsLayerInfo);
				}
			}
			if(this.RDPrsLayerInfo){
				if(!this.RNPrsLayerUtils){
					this.RDPrsLayerUtils = this.addCommonLayer(this.RDPrsLayerInfo);
				}
			}
		},
		initLinkWithDiapo(){
			this.clickedFeatures = this.map.querySourceFeatures(this.lastClickedLayer.id + "_Source", {sourceLayer: "tile"});
			this.clickedFeatures.forEach((e, index) => {
				if(e.geometry.type === "LineString"){
					e.geometry.coordinates.forEach(e2 => {
						this.pointCollection.push(turf.point([e2[0], e2[1]], {index: index}));
					});
				}
				else if(e.geometry.type === "Polygon"){
					e.geometry.coordinates[0].forEach(e2 => {
						this.pointCollection.push(turf.point([e2[0], e2[1]], {index: index}));
					});
				}
				else if(e.geometry.type === "MultiLineString"){
					e.geometry.coordinates.forEach(e2 => {
						e2.forEach(e3 => {
							this.pointCollection.push(turf.point([e3[0], e3[1]], {index: index}));
						});
					});
				}
				else if(e.geometry.type === "MultiPolygon"){
					e.geometry.coordinates.forEach(e2 => {
						e2[0].forEach(e3 => {
							this.pointCollection.push(turf.point([e3[0], e3[1]], {index: index}));
						});
					});
				}
				else {
					this.pointCollection.push(turf.point([e.geometry.coordinates[0], e.geometry.coordinates[1]], {index: index}));
				}
			});
		},
		handlePointerClick(map, layer){
			map.on("mouseenter", layer.id + "_Id", function(){
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", layer.id + "_Id", function(){
				map.getCanvas().style.cursor = "";
			});
			
			var imageSettings = layer.imageSettings;

			var docSettings = layer.floatDocSettings;
			
			
			map.on("click", layer.id + "_Id", async e => {
				this.lastClickedLayer = layer;
				this.isLoading = true;


				this.currentGid = e.features[0].properties.gid;
				this.initLinkWithDiapo();
				const itemData = await this.$axios.get(
					"map-layers/" + layer.id + "/data/" + this.currentGid,
					{showLoader: false}
				);
				this.loadedData = itemData.data;
				this.oldPopup = itemData;
				this.location = [e.lngLat.lat, e.lngLat.lng];
				/*if(
						this.selectedRoad !== null &&
						this.selectedRoad !== itemData.data.road.val
					){
						this.isDiaporamaOpen = false;
					}*/
				this.selectedRoad = itemData.data.road.val;
				this.loadPhotos(itemData.data, imageSettings).then(() => {
					this.popup = popupUtils.generatePopup(
						e.lngLat.lng,
						e.lngLat.lat,
						itemData.data,
						map,
						MapPopup,
						() => {
							this.popup = null;
							this.loadedData = null;
							this.isEditOpen = false;
						},
						null,
						layer.id
					);
					this.isLoading = false;
					this.loadFloatDocs(itemData.data, docSettings);
				});
			});

			map.on("click", () => {
				this.isDiaporamaButtonDisplayed = this.isDiaporamaOpen;
				this.$emit("closeMenu", false);
			});
		},
		handleLabelPointerClick(map, layer){
			if(!layer.displayColumn){
				return;
			}
			map.on("mouseenter", this.getLabelLayerId(layer), function(){
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", this.getLabelLayerId(layer), function(){
				map.getCanvas().style.cursor = "";
			});
			
			
			var imageSettings = layer.imageSettings;

			var docSettings = layer.floatDocSettings;

			map.on("click", this.getLabelLayerId(layer), async e => {
				const gid = e.features[0].properties.gid;
				const itemData = await this.$axios.get(
					"map-layers/" + layer.id + "/data/" + gid,
					{showLoader: false}
				);
				this.loadedData = itemData.data;
				this.loadPhotos(itemData.data, imageSettings).then(() => {
					if(this.popup){
						this.popup.closeFunc();
					}
					this.popup = popupUtils.generatePopup(
						e.lngLat.lng,
						e.lngLat.lat,
						itemData.data,
						map,
						MapPopup,
						() => {
							this.popup = null;
							this.loadedData = null;
							this.isEditOpen = false;
						},
						null,
						layer.id
					);
					this.loadFloatDocs(itemData.data, docSettings);
				});
			});
			map.on("click", () => {
				this.isDiaporamaButtonDisplayed = this.isDiaporamaOpen;
				this.$emit("closeMenu", false);
			});
		},
		async loadPhotos(itemData, imageSettings){
			if(imageSettings.length === 0){
				this.isDiaporamaButtonDisplayed = false;
				return;
			}
			await new Promise(resolve => {
				let id = itemData[imageSettings[0].dstMappedColumnName].val;
				this.$api.images
				.findBySettingsAndId(imageSettings[0].id, id)
				.then(data => {
					if(Object.keys(data).length > 0){
						this.photosData = data;
						let points = this.photosData.features.map(p =>
							turf.point([p.geometry.coordinates[1], p.geometry.coordinates[0]])
						);
						let nearestPoint = turf.nearest(
							turf.point(this.location),
							turf.featureCollection(points)
						);
						this.index = this.photosData.features.findIndex(
							e =>
								e.geometry.coordinates[1] ===
										nearestPoint.geometry.coordinates[0] &&
										e.geometry.coordinates[0] ===
										nearestPoint.geometry.coordinates[1]
						);
					}
					this.isDiaporamaButtonDisplayed =
									Object.keys(data).length !== 0;
					resolve();
				});
			});

			if(imageSettings.length >= 2){
				let id = itemData[imageSettings[1].dstMappedColumnName].val;
				this.$api.images
				.findBySettingsAndId(imageSettings[1].id, id)
				.then(data => {
					if(Object.keys(data).length > 0){
						this.backPhotosData = data;
					}
				});
			}
		},
		async loadFloatDocs(itemData, docSettings){
			if(docSettings.length === 0){
				//this.isDiaporamaButtonDisplayed = false;
				return;
			}
			let docs = [];
			
			await new Promise(resolve => {
				docSettings.forEach(docSetting => {
					let id = itemData[docSetting.dstMappedColumnName].val;
					this.$api.floatDocs
					.findBySettingsAndId(docSetting.id, id)
					.then(data => {
						data.features.forEach(feature => {
							docs.push({docSettingId: docSetting.id, path: feature.properties.path});
						});
						this.isDiaporamaButtonDisplayed = this.isDiaporamaButtonDisplayed || Object.keys(data).length !== 0;
						resolve();
					});
				});
			});

			this.floatDocData = docs;
			this.docSettings = docSettings;
			/*if(docSettings.length >= 2){
				let id = itemData[docSettings[1].dstMappedColumnName].val;
				this.$api.images
				.findBySettingsAndId(docSettings[1].id, id)
				.then(data => {
					if(Object.keys(data).length > 0){
						this.backPhotosData = data;
					}
				});
			}*/
		},
		getDataLayersIdList(layers){
			let layersIdList = [];
			layers.forEach(layer => {
				if(!layer.isContainer && layersIdList.indexOf(layer.id) === -1){
					layersIdList.push(layer.id);
				}
				else {
					let childLayerIdList = this.getDataLayersIdList(layer.children);
					layersIdList.push(...childLayerIdList);
				}
			});
			return layersIdList;
		},
		getVisibleDataLayersIdList(layers){
			let layersIdList = [];
			this.selectedLayers.forEach(selectedLayer => {
				layers.forEach(layer => {
					if(!layer.isContainer){
						let layerId = this.getLayerId(layer);
						if(selectedLayer.id === layerId && layersIdList.indexOf(layer.id) === -1){
							layersIdList.push(layer.id);
						}
					}
					else {
						let childLayerIdList = this.getVisibleDataLayersIdList(
							layer.children
						);
						layersIdList.push(...childLayerIdList);
					}
				});
			});
			return layersIdList;
		},
		reload3DLayers(layers){
			let zoomLevel = this.map.getZoom();
			let reload3DLayer = layer => {
				if(
					layer.isContainer ||
					layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED
				){
					return;
				}
				if(layer.geomType == MAPLAYER_TYPE.POLYGON3D){
					let currentMapLayerId = layer.id + "_Id";
					let currentMapLayer = this.map.getLayer(currentMapLayerId);
					let layerVisibility = this.map.getLayoutProperty(
						currentMapLayerId,
						"visibility"
					);
					let layerTileSource = currentMapLayer.sourceLayer;
					if(
						currentMapLayer &&
					layerVisibility === "visible" &&
					((layerTileSource == "3D" &&
						zoomLevel < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL) ||
						(((layerTileSource == "linestring") || (layerTileSource == "polygon")) &&
						zoomLevel >= MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL))
					){
						this.removeLayer(layer);
						this.addMapLayer(layer);
						this.filters.forEach(el => {
							if(el.id === currentMapLayerId){
								this.map.setFilter(el.id, ["any", ...el.filters]);
							}
						});
					}
				}
			};
			layers.forEach(layer => {
				if(layer.isContainer){
					this.reload3DLayers(layer.children);
				}
				else {
					reload3DLayer(layer);
				}
			});
		},
		resetLayers(){
			let that = this;
			this.map.getCanvas().style.cursor = "wait";
			this.initLayers(that.layersList).then(() => {
				that.mapLoaded = true;
				that.map.getCanvas().style.cursor = "";
				let visibleLayers = that.getVisibleDataLayersIdList(that.layersList);
				if(visibleLayers.length == 0){
					visibleLayers = that.getDataLayersIdList(that.layersList);
				}
				//that.updateLayerBounds(visibleLayers, true);
			});
		},
		beforeStyleChange(){
			if(this.AUPrsLayerInfo && this.AUPrsLayerUtils){
				this.AUPrsLayerUtils.clear();
				this.AUPrsLayerUtils = null;
			}
			if(this.RNPrsLayerInfo && this.RNPrsLayerUtils){
				this.RNPrsLayerUtils.clear();
				this.RNPrsLayerUtils = null;
			}
			if(this.RDPrsLayerInfo && this.RDPrsLayerUtils){
				this.RDPrsLayerUtils.clear();
				this.RDPrsLayerUtils = null;
			}
		},
		styleChanged(){
		},
		updateLayers(newLayersList, layers){
			let updateLayer = layer => {
				if(
					layer.isContainer ||
					layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED
				){
					return;
				}
				let visibility = "none";
				let layerId = this.getLayerId(layer);
				if(newLayersList.find(e => e.id === layerId) != undefined){
					visibility = "visible";
				}
				this.map.setLayoutProperty(layerId, "visibility", visibility);
				if(layer.displayColumn){
					let labelLayerId = this.getLabelLayerId(layer);
					this.map.setLayoutProperty(labelLayerId, "visibility", visibility);
				}
			};
			layers.forEach(layer => {
				if(!layer.isContainer){
					updateLayer(layer);
				}
				else {
					this.updateLayers(newLayersList, layer.children);
				}
			});
		},
		onLayerContentChanged(layerId){
			let sourceId = layerId + "_Source";
			this.map.getSource(sourceId).tiles = [`${process.env.VUE_APP_BASE_URL}map-layers/${layerId}/tiles/{z}_{x}_{y}.pbf?dt=${Date.now()}`];
			this.map.style._sourceCaches["other:" + sourceId].clearTiles();
			this.map.style._sourceCaches["other:" + sourceId].update(this.map.transform);
		},
	},
	mounted(){
		this.mapInit();
		this.$root.$on("flyTo", this.flyTo);
		this.$root.$on("closeCurrent", () => {
			if(this.popup){
				this.popup.closeFunc();
				this.popup = null;
				this.loadedData = null;
				this.isEditOpen = false;
			}
		});
	},
	beforeDestroy(){
		this.map.remove();
	}
};
</script>

<style lang="scss">
.bottom-left-gizmo {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 1;

  .distance-container > * {
    background-color: #fff;
    font-size: 16px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px;
  }
}
.diaporama_btn {
  font-family: "Roboto";
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-50%);
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 1;
	max-width: 250px
}
.edit_btn {
	font-family: "Roboto";
	transform-origin: left top;
	transform: rotate(90deg) translateX(-50%);
	position: absolute;
	right: -250px;
	top: 50%;
	z-index: 1;
	max-width: 250px
}
.mapboxgl-canvas {
  outline: none;
}

.mapboxgl-ctrl-geocoder {
width: auto !important;
height: 40px !important
}

.navButton{
        position: absolute;
        bottom: 25px;
        right: 10px;
    }
</style>
