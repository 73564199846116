<template>
	<nav
	class="MapPrsNav"
	>  
		<MapIconBtn
		v-if="groundSurvey.length >= 1 && campaign !== undefined ? campaign.isGroundSurvey === false : true"
		:clickEvent="setIsDisplayed"
		:activated="isDisplayedRT"
		tooltip="Afficher les relevés de terrain"
		:icon="require('@/assets/images/road/terrain.png')"
		/>

		<MapIconBtn
		v-if="AUPrsControl != null && AUPrsLayerInfo"
		:clickEvent="AUPrsControl.clickEvent"
		:activated="AUPrsControl.enabled"
		:tooltip="AUPrsControl.tooltip"
		:icon="AUPrsControl.icon"
		/>

		<MapIconBtn
		v-if="RNPrsControl != null && RNPrsLayerInfo"
		:clickEvent="RNPrsControl.clickEvent"
		:activated="RNPrsControl.enabled"
		:tooltip="RNPrsControl.tooltip"
		:icon="RNPrsControl.icon"
		/>

		<MapIconBtn
		v-if="RDPrsControl != null && RDPrsLayerInfo"
		:clickEvent="RDPrsControl.clickEvent"
		:activated="RDPrsControl.enabled"
		:tooltip="RDPrsControl.tooltip"
		:icon="RDPrsControl.icon"
		/>
	</nav>
</template>

<script>
import MAPLAYER_ZOOM from "../../utils/map/maplayerzoom.const";
import MapIconBtn from "./Map.icon-btn.vue";
import {mapGetters, mapActions} from "vuex";

export default {
	name: "MapPRsNav",
	components: {
		MapIconBtn
	},
	props: [
		"map",
		"draw",
		"measuringTapeContainerId",    
		"AUPrsLayerInfo",
		"RNPrsLayerInfo",
		"RDPrsLayerInfo",
		"currentZoom",
		"campaign"
	],
	emits: ["styleChanged"],
	data: () => ({
		navControl: [],
		isHelpPopinDisplayed: false,
		measuringTape: false,

		AUPrsEnabled: false,
		RNPrsEnabled: false,
		RDPrsEnabled: false,

		AUPrsControl: null,
		RNPrsControl: null,
		RDPrsControl: null,

		minDisplayingZoomLevel: MAPLAYER_ZOOM.SWITCH_DISPLAYING_PR
	}),
	computed: {
		...mapGetters("groundSurvey", ["groundSurvey", "isDisplayedRT"]),
	},
	watch: {},
	methods: {    
		...mapActions("groundSurvey", ["setGroundSurvey", "setIsDisplayed"]),
		displayAUPrs(){
			if(this.AUPrsLayerInfo){
				let lastConfig = this.AUPrsControl;
				if(this.AUPrsEnabled){
					lastConfig.tooltip = "PRs Autoroutes";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = false;
					this.AUPrsControl = lastConfig;
				}
				else {
					lastConfig.tooltip = "Cacher PRs Autoroute";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = true;
					this.AUPrsControl = lastConfig;
				}
				this.AUPrsEnabled = !this.AUPrsEnabled;
				this.$emit("displayAUPrs", this.AUPrsEnabled);
			}
		},
		displayRNPrs(){
			if(this.RNPrsLayerInfo){
				let lastConfig = this.RNPrsControl;
				if(this.RNPrsEnabled){
					lastConfig.tooltip = "PRs Routes Nationales";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = false;
					this.RNPrsControl = lastConfig;
				}
				else {
					lastConfig.tooltip = "Cacher PRs Routes Nationales";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = true;
					this.RNPrsControl = lastConfig;
				}
				this.RNPrsEnabled = !this.RNPrsEnabled;
				this.$emit("displayRNPrs", this.RNPrsEnabled);
			}
		},
		displayRDPrs(){
			if(this.RDPrsLayerInfo){
				let lastConfig = this.RDPrsControl;
				if(this.RDPrsEnabled){
					lastConfig.tooltip = "PRs Routes Départementales";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = false;
					this.RDPrsControl = lastConfig;
				}
				else {
					lastConfig.tooltip = "Cacher PRs Routes Départementales";
					lastConfig.color = "#ff0000";
					lastConfig.enabled = true;
					this.RDPrsControl = lastConfig;
				}
				this.RDPrsEnabled = !this.RDPrsEnabled;
				this.$emit("displayRDPrs", this.RDPrsEnabled);
			}
		},
		updateMap(){
			if(this.AUPrsLayerInfo){
				this.$emit("displayAUPrs", this.AUPrsEnabled);
			}
			if(this.RNPrsLayerInfo){
				this.$emit("displayRNPrs", this.RNPrsEnabled);
			}
			if(this.RDPrsLayerInfo){
				this.$emit("displayRDPrs", this.RDPrsEnabled);
			}
		},
		async getGroundSurvey(){
			const userId = this.$store.state.user.current.userId;
			if(undefined === userId) return setTimeout(this.getGroundSurvey, 500);
			await this.setGroundSurvey(this.$store.state.user.current.userId);
		}
	},  
	/**
   * @description set nav control with custom event, icon and tooltip
   * @returns void
   */
	async mounted(){
		this.getGroundSurvey();
		this.AUPrsControl = {
			clickEvent: this.displayAUPrs,
			icon: require("@/assets/images/road/PRA.png"),
			tooltip: "PRs Autoroutes",
			enabled: this.AUPrsEnabled,
			color: "filter-blue"
		};
    
		this.RNPrsControl = {
			clickEvent: this.displayRNPrs,
			icon: require("@/assets/images/road/PRN.png"),
			tooltip: "PRs Routes Nationales",
			enabled: this.RNPrsEnabled,
			color: "filter-green"
		};
    
		this.RDPrsControl = {
			clickEvent: this.displayRDPrs,
			icon: require("@/assets/images/road/PRD.png"),
			tooltip: "PRs Routes Départementales",
			enabled: this.RDPrsEnabled,
			color: "filter-white"
		};
	},
	created(){}
};
</script>

<style lang="scss" scoped>
.MapPrsNav {
  position: absolute;
  bottom: 75px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  transition: bottom 0.5s ease;
  z-index: 1;
}
</style>
