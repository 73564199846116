var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-h-full tw-relative tw-overflow-hidden"},[(_vm.isLoading)?_c('WaitingSlot',{staticClass:"tw-z-[5] tw-absolute tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.12)]",attrs:{"theme":"white","size":"200px"}}):_vm._e(),_c('AuscultationDiaporamaMap',{staticClass:"tw-absolute tw-transition-[all]",class:{
		'tw-translate-x-full': _vm.isDiaporamaOpen
	},attrs:{"map":_vm.map,"photosGeoJson":_vm.photosData,"backPhotosGeoJson":_vm.backPhotosData,"floatDocGeoJson":_vm.floatDocData,"docSettings":_vm.docSettings,"isOpen":_vm.isDiaporamaOpen,"indexPopup":_vm.index,"isGroundSurvey":_vm.campaign.isGroundSurvey},on:{"updateModal":function($event){return _vm.updateModalInfos($event)},"closeModal":function($event){_vm.isDiaporamaOpen = false},"reopenModal":function($event){_vm.isDiaporamaOpen = true}}}),_c('AuscultationFormMap',{staticClass:"tw-absolute",class:{
		'tw-translate-x-full': _vm.isEditOpen,
		'tw-left-full' : !_vm.isEditOpen
	},attrs:{"loadedData":_vm.loadedData,"mapLayerId":_vm.lastClickedLayer ? _vm.lastClickedLayer.id : null},on:{"layerContentChanged":_vm.onLayerContentChanged}}),_c('div',{staticClass:"tw-w-full tw-h-full tw-overflow-hidden tw-relative",attrs:{"id":"mapContainer"}},[_vm._t("default"),_c('div',{ref:"geocoder",staticClass:"tw-absolute tw-z-[1] tw-top-[10px] tw-right-[15px]",attrs:{"id":"geocoder"}}),_c('map-prs-nav',{ref:"prsNav",attrs:{"map":_vm.map,"draw":_vm.draw,"AUPrsLayerInfo":_vm.AUPrsLayerInfo,"RNPrsLayerInfo":_vm.RNPrsLayerInfo,"RDPrsLayerInfo":_vm.RDPrsLayerInfo,"measuringTapeContainerId":'distance',"currentZoom":_vm.lastZoom,"campaign":_vm.campaign},on:{"displayAUPrs":_vm.displayAUPrs,"displayRNPrs":_vm.displayRNPrs,"displayRDPrs":_vm.displayRDPrs}}),_c('MapNavButton',{staticClass:"navButton",staticStyle:{"z-index":"2"},attrs:{"map":_vm.map,"draw":_vm.draw}}),(_vm.isDiaporamaButtonDisplayed)?_c('v-btn',{staticClass:"tw-absolute tw-transition-[all] diaporama_btn",class:{
			'tw-left-[50%]': _vm.isDiaporamaOpen
		},attrs:{"depressed":"","color":"#f1f1f1"},on:{"click":function($event){_vm.isDiaporamaOpen = !_vm.isDiaporamaOpen}}},[_vm._v(" "+_vm._s(_vm.isDiaporamaOpen ? "Fermer diaporama" : "Afficher diaporama")+" "),_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.isDiaporamaOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1):_vm._e(),(_vm.loadedData !== null && _vm.$hasRight('campaigns.openAuscultationForm'))?_c('v-btn',{staticClass:"tw-absolute tw-transition-[all] edit_btn",class:{
			'tw-left-[80%]': _vm.isEditOpen
		},attrs:{"depressed":"","color":"#f1f1f1"},on:{"click":function($event){_vm.isEditOpen = !_vm.isEditOpen}}},[_vm._v(" "+_vm._s(_vm.isEditOpen ? "Fermer le formulaire" : "Afficher le formulaire")+" "),_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.isEditOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1):_vm._e(),_vm._m(0)],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-left-gizmo"},[_c('div',{staticClass:"distance-container",attrs:{"id":"distance"}})])}]

export { render, staticRenderFns }